<template>
  <div id="wangeditor">
    <div ref="editorElem" style="text-align:left"></div>
  </div>
</template>

<script>
  import E from '@/assets/wangeditor'
  import emojiJSON from '@/utils/emoji'

  export default {
    props: ['editortext', 'disMenus'],
    watch: {
      'editortext':{
        handler:function (newdata) {
          if(!this.isChange){
            this.setHtml(newdata)
          }
          this.isChange= false;
        }
      },
      /*'editortext'(newdata) {
        this.setHtml(newdata)
        return newdata
      }*/
    },
    data() {
      return {
        editor: '',
        editorContent: this.editortext,
        neweditor: function () {
          const _this = this;
          var editor = new E(this.$refs.editorElem)
          editor.customConfig.zIndex = 2000;
          // editor.customConfig.uploadImgShowBase64 = true;//图片以base64形式保存
          let menus = [
            'head',  // 标题
            'bold',  // 粗体
            'fontSize',  // 字号
            // 'fontName',  // 字体
            'italic',  // 斜体
            'underline',  // 下划线
            'strikeThrough',  // 删除线
            'foreColor',  // 文字颜色
            'backColor',  // 背景颜色
            'link',  // 插入链接
            'list',  // 列表
            'justify',  // 对齐方式
            'quote',  // 引用
            // 'emoticon',  // 表情
            'image',  // 插入图片
            'table',  // 表格
            'video',  // 插入视频
            // 'code',  // 插入代码
            'undo',  // 撤销
            'redo'  // 重复
          ]
          if(_this.disMenus) {
            menus = menus.filter(item => item != _this.disMenus)
          }
          editor.customConfig.menus = menus
          editor.customConfig.customUploadImg = function (files, insert) {
            // files 是 input 中选中的文件列表     
            // insert 是获取图片 url 后，插入到编辑器的方法   
            files.forEach((item) => {
              let fd = new FormData();
              fd.append('file', item);
              _this.$upLoadFile('course', item).then((res) => {
                // 上传代码返回结果之后，将图片插入到编辑器中       
                insert(res)
              })
            })
          }
          editor.customConfig.customUploadVideo = function (files, insert) {
            // files 是 input 中选中的文件列表     
            // insert 是获取图片 url 后，插入到编辑器的方法   
            files.forEach((item) => {
              let fd = new FormData();
              fd.append('file', item); // 上传的文件： 键名，键值     
              // 以下是上传图片的接口，视情况而更改就可以啦                                                     teachService.FileUploadInTencent(fd).then((data) => {       
              _this.$upLoadFile('course' ,item).then((res) => {
                // 上传代码返回结果之后，将图片插入到编辑器中       
                insert(res)
              })
            })

          }
          editor.customConfig.onchange = (html) => {
            this.isChange = true;
            this.editorContent = html
            _this.$emit('changeHtml', html);
          }
          editor.customConfig.pasteTextHandle = function (content) {
            if (content == '' && !content) return ''
            var str = content
            str = str.replace(/<xml>[\s\S]*?<\/xml>/ig, '')
            str = str.replace(/<style>[\s\S]*?<\/style>/ig, '')
            str = str.replace(/<\/?[^>]*>/g, '')
            str = str.replace(/[ | ]*\n/g, '\n')
            str = str.replace(/&nbsp;/ig, '')
            return str
          }
          editor.customConfig.emotions = [
            {
              title: '默认',
              type: 'image',
              content: this.emojiList
            }
          ]
          editor.create()
          editor.txt.html(this.editorContent);
          document.querySelector('.w-e-toolbar .w-e-icon-bold').setAttribute('title', '加粗');
          document.querySelector('.w-e-toolbar .w-e-icon-italic').setAttribute('title', '倾斜');
          document.querySelector('.w-e-toolbar .w-e-icon-underline').setAttribute('title', '下划线');
          document.querySelector('.w-e-toolbar .w-e-icon-strikethrough').setAttribute('title', '删除线');
          document.querySelector('.w-e-toolbar .w-e-icon-link').setAttribute('title', '链接');
          document.querySelector('.w-e-toolbar .w-e-icon-quotes-left').setAttribute('title', '引用块');
          // document.querySelector('.w-e-toolbar .w-e-icon-happy').setAttribute('title', '表情');
          document.querySelector('.w-e-toolbar .w-e-icon-image').setAttribute('title', '图片');
          document.querySelector('.w-e-toolbar .w-e-icon-table2').setAttribute('title', '表格');
          if(_this.disMenus != 'video') {
            document.querySelector('.w-e-toolbar .w-e-icon-play').setAttribute('title', '视频');
          }
          // document.querySelector('.w-e-toolbar .w-e-icon-terminal').setAttribute('title', '插入代码');
          document.querySelector('.w-e-toolbar .w-e-icon-undo').setAttribute('title', '撤销');
          document.querySelector('.w-e-toolbar .w-e-icon-redo').setAttribute('title', '还原');
          _this.editor = editor;
        },
        emojiList: emojiJSON,
        isChange: false,    //定义判读是否改变的变量
      }
    },
    mounted() {
      this.neweditor()
      this.$refs["editorElem"].addEventListener("input", this.inputEvent)
    },
    methods: {
      inputEvent() {
        let html = this.editor.txt.html();
        if (html === '' || html === '<p><br></p>') {
          this.editor.customConfig.onchange()
        }
      },
      getHtml() {
        return this.editor.txt.html();
      },
      setHtml(txt) {
        this.editor.txt.html(txt);
      },
      getText() {
        return this.editor.txt.text();
      }
    }
  }
</script>

<style>
</style>
